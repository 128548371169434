import React from 'react';
import Layout from "../components/layout";
import Seo from "../components/seo";

const divParaStyle = {
  marginTop: '70px'
}

const Datenschutz = () => {
  return (
      <Layout>
        <Seo title="Datenschutz" />
        <div className="content">
          <h1>Datenschutzerklärung</h1>
            <div style={divParaStyle}>
              <p>Unsere Verarbeitung personenbezogener Daten unserer Nutzer beschränkt sich auf jene Daten, die zur Bereitstellung einer funktionsfähigen Internetseite sowie unserer Inhalte und Leistungen erforderlich sind. Die Verarbeitung personenbezogener Daten unserer Nutzer erfolgt nur zu den unten aufgeführten Zwecken und wenn eine rechtliche Grundlage (im Sinn der DSGVO) vorliegt. Es werden nur solche personenbezogenen Daten erhoben, die für die Durchführung und Abwicklung unserer Aufgaben und Leistungen tatsächlich erforderlich sind oder die Sie uns freiwillig zur Verfügung gestellt haben.</p>
            </div>
            <div style={divParaStyle}>
              <h3>Bereitstellung der Webseite</h3>
              <p>Wir führen auf unserer Internetseite keine eigenen Webanalysen durch und verwenden auch kein Webanalyse-Tool. Somit erfolgt keine Auswertung der Besucher- und Nutzungsdaten.</p>
            </div>
            <div style={divParaStyle}>
              <h3>Kontakt</h3>
              <p>Senden Sie uns eine Mail oder eine sonstige elektronische Nachricht. Ihre Angaben werden nur für die Bearbeitung der Anfrage und/oder mögliche damit zusammenhängende weitere Fragen, gespeichert und nur im Rahmen der Anfrage verwendet. Wir weisen darauf hin, dass die Datenübertragung Sicherheitslücken aufweisen kann. Vollkommener Schutz vor dem Zugriff durch Dritte ist nicht möglich.</p>
              <p>Rechtsgrundlage für die Bearbeitung Ihrer Anfrage ist Art. 6 Abs. 1 lit. a oder b DSGVO.</p>
              <p>Wir werden Ihre personenbezogenen Daten nach Ablauf des Vertrages löschen.</p>
            </div>
            <div style={divParaStyle}>
              <h3>Ihre Rechte</h3>
              <p>Sie haben das Recht, Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorien der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, Datenübertragung, die Herkunft Ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschliesslich Profiling verlangen. Sie haben auch das Recht, eine allenfalls erteilte Einwilligung zur Nutzung Ihrer personenbezogenen Daten jederzeit zu widerrufen.</p>
              <p>Sie können Ihre genannten Rechte jederzeit bei uns geltend machen unter der angegebenen Kontaktadresse.</p>
            </div>
        </div>
      </Layout>
  )
}

export default Datenschutz;